<template>
  <div class="app-EditInformation">
    <div class="EditInformation-wrapper">
      <el-row>
        <el-col :span="24">
          <div style="display: flex">
            <el-col :span="24" style="margin-left: 20px">
              <div class="grid-content bg-purple-dark" style="text-align: left">
                <h1
                    style="
                    font-size: 30px;
                    font-weight: 500;
                    margin: 0px 0px 10px 0px;
                  "
                >
                  {{ this.$t('Edit.information') }}
                </h1>
                <hr style="opacity: 0.4"/>
                <el-form
                    ref="ruleForm"
                    :model="listQuery"
                    class="demo-ruleForm"
                    label-width="auto"
                >
                  <el-form-item :label="$t('csServiceCenter.trueName')">
                    &nbsp;&nbsp;{{ listQuery.name }}
                  </el-form-item>
                  <el-form-item :label="$t('sys.email')">
                    <el-input readonly v-model="listQuery.email" clearable />
                    <p v-if="!listQuery.email" style="color: red">{{this.$t('Please.fill.in.the.email')}}</p>
                  </el-form-item>
                  <!-- 这个注释代码别动(判断邮箱修改三次)-->
                  <!--  <el-form-item v-if="listQuery.mailUpdateNum <3" :label="$t('sys.email')">
                    <el-input v-model="listQuery.email" clearable />
                    <p v-if="!listQuery.email" style="color: red">{{this.$t('Please.fill.in.the.email')}}</p>
                  </el-form-item>-->
                  <el-form-item v-if="listQuery.mailUpdateNum >= 3" :label="$t('sys.email')">
                     &nbsp;&nbsp;{{ listQuery.email }}
                  </el-form-item>
                  <el-form-item :label="$t('sys.phone')">
                    <el-input v-model="listQuery.mobile" clearable />
                  </el-form-item>
                  <el-form-item
                      :label="$t('Shipping.address.list')"
                      required
                  >
                    <el-row>
                      <el-col :lg="12" :xs="24">
                        <!-- 国家 -->
                        <el-select
                            v-model="countrymodel"
                            :placeholder="$t('poOrder.pleaseChoose')"
                            clearable
                            @change="countrySelect"
                        >
                          <el-option
                              v-for="item in addchoicecountry"
                              :key="item.id"
                              :label="item.country"
                              :value="item.country"
                          />
                        </el-select>
                      </el-col>
                      <el-col :lg="12" :xs="24">
                        <!-- 省 -->
                        <el-select
                            :loading="doLoading"
                            v-model="provincemodel"
                            :placeholder="$t('poOrder.pleaseChoose')"
                            clearable
                            @change="citySelect"
                        >
                          <el-option
                              v-for="item in addchoiceprovince"
                              :key="item.id"
                              :label="item.province"
                              :value="item.id"
                          />
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item :label="$t('mi.addrees.detail')" required>
                    <el-input v-model="listQuery.storeAddr"></el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button >{{ this.$t('operation.button.cancel') }} </el-button>
                     <el-button style="margin-left: 10px" :loading="btnloading" type="primary" @click="edit()">
                          {{ this.$t('btn.implement') }}
                    </el-button>
                  </span>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {miMemberInfo, memberInfoEdit} from '@/api/userInfo/userInfo.js'
import {commonGetRecAddrs, getRecAddrsWEU} from "@/api/rus/api"

export default {
  name: 'EditInformation',
  data() {
    return {
      oldEmail: '',
      regionCodeList: [],
      labelPosition: 'right',
      listLoading: false,
      doLoading: false,
      btnloading: false,
      listQuery: {
        mailUpdateNum: '',
        name: '',
        email: '',
        mobile: '',
        storeAddr: '',
        page: 1,
        limit: 20,
      },
      countrymodel: '', // 新增选择地区model
      provincemodel: '', // 新增选择省存储model
      citymodel: '', // 新增选择市存储model
      areamodel: '', // 新增选择县存储model
      addchoicecountry: [], // 新增选择国家存储
      addchoiceprovince: [], // 新增选择省存储
      addchoicecity: [], // 新增选择市存储
      addchoicearea: [], // 新增选择县存储
    }
  },
  computed: {
    ...mapGetters(['userCode']),
  },
  watch: {},
  mounted() {
    this.getList(this.userCode)
    getRecAddrsWEU().then(res => {
      this.addchoicecountry = res.data
    }).catch(err => {
      console.warn(err)
    })
  },
  methods: {
    getAddrList(countryCode) {
      return new Promise((resolve, reject)=>{
        getRecAddrsWEU({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    citySelect(val) {
      this.listQuery.regionId = val
    },
    countrySelect(val) {
      this.doLoading = true
      this.provincemodel = ''
      commonGetRecAddrs({country: val}).then(res => {
        this.addchoiceprovince = res.data
        this.doLoading = false
      }).catch(err => {
        console.warn(err)
        this.doLoading = false
      })
    },
    getList() {
      this.listLoading = true
      miMemberInfo({memberNo: this.userCode})
          .then(async (res) => {
            this.listLoading = false
            this.listQuery.mailUpdateNum = res.data.mailUpdateNum === null ? 0 :  res.data.mailUpdateNum
            this.listQuery.name = res.data.name
            this.listQuery.email = res.data.email
            this.oldEmail = res.data.email
            this.listQuery.mobile = res.data.mobile
            this.listQuery.storeAddr = res.data.storeAddr
            this.countrymodel = res.data.cityName
            await this.countrySelect(this.countrymodel)
            this.provincemodel = Number(res.data.districtId)
            this.list = res.data.records
            this.total = +res.data.total
          })
          .catch((err) => {
            console.error(err)
            this.listLoading = false
          })
    },
    edit() {
      this.btnloading = true
      this.listQuery.isMailUpdate = this.oldEmail !== this.listQuery.email;
      memberInfoEdit({...this.listQuery, memberNo: this.userCode}).then(res => {
        if (res.success) {
          this.getList()
          this.btnloading = false
          this.$notify({
            title: '',
            message: this.$t('mi.memberinfo.succesful'),
            type: 'success'
          })
        }
      }).catch(err => {
        console.warn(err)
        this.btnloading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}

.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}

.el-button + .el-button {
  margin-left: 0;
}

.btn {
  width: 100%;
  text-align: left;
  border-bottom: 0px;
  border-radius: 0;
}

.el-form-item {
  margin-top: 30px;
  width: 80%;
  margin-bottom: 15px;
}
</style>
